const Environment = 'Dev';

const URL = {
  Local: 'http://localhost/pim-products',
  Live: 'https://pim.stadlerform.com',
  Dev: 'https://pimtest.stadlerform.com',
};

export const base_url = URL[Environment];
export const serverUrl = `${URL[Environment]}/api`;

export const AvailableLang = ['en', 'de', 'fr', 'it'];
